
<script lang="ts" setup>
withDefaults(defineProps<{
  prefix?: string
  name: string
  color?: string
  size?: string
  title?: string
}>(), { prefix: 'icon',size: '16px', })

const symbolId = computed(() => `#${__props.prefix}-${__props.name}`)
</script>

<template>
  <svg
    aria-hidden="true"
    class="g-icon"
    :class="[
      color && !color.startsWith('#') && `text-${color}`,
      `g-icon-${name}`,
      (name.startsWith('new-misc') || name.startsWith('misc-') || name.startsWith('card-')) && 'g-illustration',
    ]"
    :name="name"
    :aria-label="name"
    :style="color?.startsWith('#') && { color }"
  >
    <title v-if="title">{{ title }}</title>
    <use :href="symbolId"/>
  </svg>
</template>
<style lang="scss" scoped>
.g-icon {
  fill: currentcolor;
  height: v-bind(size);
  max-width: initial;
  stroke: currentcolor;
  width: v-bind(size);
}

.g-illustration {
  fill: initial;
  stroke: initial;
}
</style>
